<template>
  <div v-if="shoppingGuideData.personas">
    <h3 class="mb-4 text-lg font-semibold">
      {{ $t("magic_search_two_point.modal.shop_by_usage") }}
    </h3>
    <div class="grid grid-cols-1 gap-4">
      <BaseButton
        v-for="persona in shoppingGuideData.personas.personas"
        :key="persona?.name"
        v-track:click="{
          ...$EVENTS.HYBRID.MAGIC_SHOPPING_GUIDE_SEARCH_CLICK,
          magic_ad_source: 'personas',
        }"
        variant="tertiary"
        class="justify-between"
        @click="makeSearch(persona.search_query)"
      >
        <div class="flex gap-2">
          <LuicideIcon
            v-if="persona.icon"
            :name="persona.icon"
            class="size-4 shrink-0 text-teal-600"
            :stroke-width="2"
          />
          <span class="whitespace-nowrap font-medium">{{ persona.name }}</span
          ><span class="whitespace-nowrap text-sm text-gray-600">{{
            persona.feature
          }}</span>
        </div>
        <SearchIcon class="size-4 text-gray-400" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchIcon from "@/components/ui/icons/SearchIcon.vue";
import LuicideIcon from "@/components/ui/LuicideIcon.vue";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "Personas",
  components: { SearchIcon, LuicideIcon, BaseButton },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: { ...mapActions("guideActions", ["makeSearch"]) },
};
</script>
