<template>
  <div
    v-if="shoppingGuideEntry"
    v-track:click="$EVENTS.HYBRID.MAGIC_SHOPPING_GUIDE_CLICK"
    :class="
      $twMerge(
        'flex items-center px-2 rounded-lg mx-4 mt-6',
        'bg-white border border-neutral-300 cursor-pointer',
      )
    "
    @click="openShoppingGuide"
  >
    <div class="rounded-lg bg-magic-50 p-2">
      <LuicideIcon
        :name="shoppingGuideEntry.icon"
        class="size-5 text-magic-700"
        :stroke-width="2"
      />
    </div>
    <div class="flex flex-1 flex-col justify-center gap-2 p-3">
      <div class="text-sm font-medium">{{ shoppingGuideEntry.title }}</div>
      <div class="text-xs">{{ shoppingGuideEntry.tagline }}</div>
    </div>
    <div class="mr-2 flex">
      <SortFilterIcon class="size-7 text-grey-500" />
    </div>
  </div>
  <ShoppingGuideModal />
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import ShoppingGuideModal from "./actions/ShoppingGuideModal";
import LuicideIcon from "@/components/ui/LuicideIcon.vue";
import SortFilterIcon from "@/components/ui/icons/SortFilterIcon.vue";
import TrackingService from "../../services/TrackingService";

export default {
  name: "ShoppingGuide",
  components: { SortFilterIcon, LuicideIcon, ShoppingGuideModal },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideEntry"]),
    ...mapGetters("search", ["searchId"]),
  },
  watch: {
    shoppingGuideEntry(newValue) {
      if (newValue) {
        TrackingService.trackEvent(
          "click",
          this.$EVENTS.HYBRID.MAGIC_SHOPPING_GUIDE_VIEWED,
        );
      }
    },
  },
  methods: {
    ...mapMutations("guideActions", ["SET_SHOPPING_GUIDE_MODAL_OPEN"]),
    ...mapActions("guideActions", ["fetchShoppingGuideData"]),
    openShoppingGuide() {
      this.SET_SHOPPING_GUIDE_MODAL_OPEN(true);
      this.fetchShoppingGuideData(this.searchId);
    },
  },
};
</script>
