<template>
  <div class="flex h-full flex-col overflow-hidden">
    <!-- Filters -->
    <div v-if="searchId" class="flex flex-col p-4">
      <h2 class="text-base font-medium uppercase leading-4 text-magic-800">
        {{ $t("magic_search_two_point.modal_header.searching_for") }}
      </h2>
      <SearchBarTags class="mt-2" />
    </div>

    <!-- Refine -->
    <div class="flex flex-col gap-2 p-4">
      <h2 class="text-base font-medium uppercase leading-4 text-magic-800">
        {{ $t("magic_search_two_point.modal_header.refine") }}
      </h2>
      <RefineContent />
    </div>

    <!-- Reasonings -->
    <Reasonings v-if="showReasonings" />

    <!-- Compare -->
    <div class="flex grow flex-col overflow-hidden p-4">
      <Transition name="slide-left" duration="300">
        <div
          v-if="showHeader"
          class="flex shrink-0 items-center justify-between transition-all duration-300"
        >
          <h2
            class="pt-1 text-base font-medium uppercase leading-4 text-magic-800"
          >
            {{ $t("magic_search_two_point.modal_header.compare_results") }}
          </h2>
          <SelectedAdCounter />
        </div>
      </Transition>
      <SmartCompareResult class="mb-2 overflow-y-auto" />
      <BaseButton
        v-track:click="$EVENTS?.HYBRID?.MAGIC_SMART_COMPARE"
        variant="primary"
        class="mt-2 w-full shrink-0"
        :disabled="compareDisabled"
        @click="onCompare"
      >
        <CompareIcon class="size-5" />
        <span class="text-sm font-medium">
          {{
            $t(
              "magic_search_two_point.action_bar_button_text.compare_selection",
            )
          }}
        </span>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapActions, mapGetters } from "vuex";
import CompareIcon from "./actions/CompareIcon.vue";
import RefineContent from "./actions/RefineContent.vue";
import SelectedAdCounter from "./actions/SelectedAdCounter.vue";
import SmartCompareResult from "./actions/SmartCompareResult.vue";
import SearchBarTags from "./search-bar/SearchBarTags.vue";
import Reasonings from "./Reasonings.vue";

export default {
  name: "SidePanel",
  components: {
    RefineContent,
    SearchBarTags,
    BaseButton,
    CompareIcon,
    SmartCompareResult,
    SelectedAdCounter,
    Reasonings,
  },
  data() {
    return {
      showReasonings:
        process.env.VUE_APP_STAGE !== "production" &&
        process.env.VUE_APP_STAGE !== "staging",
    };
  },
  computed: {
    ...mapGetters("hybridActions", [
      "selectedAdIds",
      "compareLoading",
      "compareResult",
    ]),
    ...mapGetters("search", ["searchId"]),
    compareDisabled() {
      return this.selectedAdIds.length < 2 || !!this.compareLoading;
    },
    showHeader() {
      return this.selectedAdIds.length > 0 || !!this.compareResult;
    },
  },
  methods: {
    ...mapActions("hybridActions", ["compare"]),
    onCompare() {
      this.compare();
    },
  },
};
</script>
