export function parseSearchToVueQuery(search) {
  const queryString = search.startsWith("?") ? search.slice(1) : search;

  const queryParams = {};
  const pairs = queryString.split("&");

  for (const pair of pairs) {
    if (pair) {
      const [key, value] = pair.split("=").map(decodeURIComponent);
      if (queryParams[key]) {
        queryParams[key] = Array.isArray(queryParams[key])
          ? [...queryParams[key], value]
          : [queryParams[key], value];
      } else {
        queryParams[key] = value;
      }
    }
  }

  return queryParams;
}
