import logger from "@/services/logger";

export function isEmbedded() {
  return isInIframe() && isSameHost();
}

export function isInIframe() {
  return window.self !== window.top;
}

export function getParentHostname() {
  try {
    return window.top.location.hostname;
  } catch (e) {
    logger.error("Error getting parent hostname", { error: e });
    return null;
  }
}

export function isSameHost() {
  return getParentHostname() === window.location.hostname;
}
