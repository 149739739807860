<template>
  <div v-if="!isInIframe" class="navbar-height sticky top-0 z-50 block w-full">
    <Disclosure as="nav">
      <div
        :class="
          $twMerge(
            'navbar-height flex items-center',
            'bg-grey-700 px-5 py-3',
            'sm:px-16 sm:py-2',
          )
        "
        translate="no"
      >
        <div
          :class="
            $twMerge(
              'container relative mx-auto flex items-center justify-between',
              'px-0',
            )
          "
        >
          <!-- Adding this empty <div> to center-align the OLX Magic logo -->
          <div
            :class="isAuthenticated ? 'h-6 w-6 hidden' : 'h-10 w-20 sm:hidden'"
          ></div>
          <!-- Logo and links -->
          <div
            :class="
              $twMerge(
                'flex items-start justify-center',
                'sm:items-stretch sm:justify-start',
              )
            "
          >
            <div :class="$twMerge('flex cursor-pointer items-center')">
              <img
                v-track:click="MAGIC_BANNER_TO_HOMEPAGE"
                :class="$twMerge('h-6 sm:my-2 sm:h-auto')"
                src="@/assets/icons/logo.svg"
                alt="OLX Magic"
                @click="navigateToHome"
              />
            </div>
            <div :class="$twMerge('hidden sm:ml-6 sm:block')">
              <div :class="$twMerge('flex space-x-4')">
                <router-link
                  v-for="link in filteredLinks"
                  :key="link.name"
                  :to="link.href"
                  :class="
                    $twMerge(
                      'rounded-md px-3 py-2 text-sm font-medium text-gray-700',
                      'hover:bg-gray-200 hover:text-gray-900',
                    )
                  "
                  active-class="bg-gray-200 text-gray-900"
                >
                  {{ link.name }}
                </router-link>
              </div>
            </div>
          </div>
          <ProfileButton v-if="showProfiler" />
          <!-- Authentication buttons -->
          <div :class="$twMerge('flex items-center')">
            <LoginButton />
            <div :class="$twMerge('flex gap-2')">
              <div
                v-if="
                  isAuthenticated && $route.path === '/buy' && messages.length
                "
                v-track:click="MAGIC_NEW_CONVERSATION"
                :class="
                  $twMerge('relative flex cursor-pointer items-center gap-2')
                "
                @click="createNewSession"
              >
                <!-- Triggering element -->
                <Dropdown
                  :class="$twMerge('inline')"
                  popper-class="custom-dropdown w-80 md:w-96"
                  :shown="onboardingTipVisible.new_conversation"
                  :auto-hide="true"
                  placement="bottom"
                  append-to="parent"
                >
                  <img
                    class="dropdown-trigger size-6 transition duration-150 ease-in-out"
                    src="@/assets/icons/new_chat.svg"
                    alt="new-chat-icon"
                  />
                  <!-- Popper content -->
                  <template #popper="{ hide }">
                    <DropdownPopper
                      :hide="hide"
                      :tooltip-text="'tour_tooltip_text.new_conversation'"
                    />
                  </template>
                </Dropdown>
              </div>
              <template v-if="modeSwitchEnabled">
                <div
                  v-if="isAuthenticated && $route.path === '/buy'"
                  :class="$twMerge('flex cursor-pointer items-center gap-2')"
                  @click="handleBackOrRedirect"
                >
                  <MagicSearchIcon class="size-6 text-white" />
                </div>
                <SwitchToChatButton v-if="isAuthenticated" class="text-white" />
              </template>
              <HeadlessMenu
                v-if="isAuthenticated"
                v-slot="{ open, close }"
                as="div"
                :class="$twMerge('relative')"
              >
                <HeadlessMenuButton
                  v-track:click="MAGIC_MY_OLX"
                  :class="
                    $twMerge('flex rounded-full text-sm focus:outline-none')
                  "
                >
                  <div :class="$twMerge('flex items-center gap-2')">
                    <img
                      :class="$twMerge('size-6')"
                      src="@/assets/icons/profile_icon.svg"
                      alt="profile-icon"
                    />
                    <div
                      :class="
                        $twMerge(
                          'hidden font-olx text-base font-normal leading-5 text-white',
                          'sm:block',
                        )
                      "
                    >
                      <h1 :class="$twMerge('h-4')">
                        {{ $t("navbar.profile") }}
                      </h1>
                    </div>
                  </div>
                </HeadlessMenuButton>
                <Transition>
                  <HeadlessMenuItems
                    v-show="open"
                    :static="true"
                    :class="
                      $twMerge(
                        'absolute right-0 z-20 mt-2 w-48 origin-top-right',
                        'rounded-md bg-white py-1 shadow-lg',
                        'ring-1 ring-black/5 focus:outline-none',
                      )
                    "
                  >
                    <HeadlessMenuItem :class="$twMerge('z-20')">
                      <button
                        v-track:click="MAGIC_MY_OLX_SETTINGS"
                        :class="
                          $twMerge(
                            'flex w-full items-center gap-2 px-4 py-2',
                            'text-sm text-gray-700 hover:bg-gray-100',
                          )
                        "
                        @click="showSettings"
                      >
                        <img
                          :class="$twMerge('')"
                          src="@/assets/icons/settings.svg"
                          alt="Settings"
                        />
                        <div :class="$twMerge('mt-1')">
                          {{ $t("navbar.menu.setting") }}
                        </div>
                      </button>
                    </HeadlessMenuItem>
                    <HeadlessMenuItem v-if="MOBILE_CTA_VISIBLE">
                      <DownloadButtonTrigger @click="close" />
                    </HeadlessMenuItem>
                    <HeadlessMenuItem>
                      <RouterLink to="/privacy" target="_blank">
                        <div
                          v-if="isAuthenticated"
                          :class="
                            $twMerge(
                              'flex w-full items-center gap-2 px-4 py-2',
                              'text-sm text-gray-700 hover:bg-gray-100',
                            )
                          "
                        >
                          <img
                            :class="$twMerge('')"
                            src="@/assets/icons/more-info.svg"
                            alt="info"
                          />
                          <div :class="$twMerge('mt-1')">
                            {{ $t("magic_search_two_point.policies.privacy") }}
                          </div>
                        </div>
                      </RouterLink>
                    </HeadlessMenuItem>
                    <HeadlessMenuItem>
                      <RouterLink to="/terms-conditions" target="_blank">
                        <div
                          v-if="isAuthenticated"
                          :class="
                            $twMerge(
                              'flex w-full items-center gap-2 py-2 pl-4',
                              'text-sm text-gray-700 hover:bg-gray-100',
                            )
                          "
                        >
                          <img
                            class=""
                            src="@/assets/icons/document.svg"
                            alt="T&C"
                          />
                          <div :class="$twMerge('mt-1')">
                            {{ $t("magic_search_two_point.policies.terms") }}
                          </div>
                        </div>
                      </RouterLink>
                    </HeadlessMenuItem>
                    <HeadlessMenuItem>
                      <RouterLink to="/cookies" target="_blank">
                        <div
                          v-if="isAuthenticated"
                          :class="
                            $twMerge(
                              'flex w-full items-center gap-2 px-4 py-2',
                              'text-sm text-gray-700 hover:bg-gray-100',
                            )
                          "
                        >
                          <img
                            class=""
                            src="@/assets/icons/Cookie.svg"
                            alt="cookie"
                          />
                          <div :class="$twMerge('mt-1')">
                            {{ $t("magic_search_two_point.policies.cookie") }}
                          </div>
                        </div>
                      </RouterLink>
                    </HeadlessMenuItem>
                    <HeadlessMenuItem>
                      <LogoutButton />
                    </HeadlessMenuItem>
                  </HeadlessMenuItems>
                </Transition>
              </HeadlessMenu>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
    <SideModal v-if="settingsVisible && !smallerMD" @close="closeSettings">
      <Settings translate="no" />
    </SideModal>
    <BottomSheet
      :heading="$t('settings.title')"
      :button-text="''"
      :visible="settingsVisible && smallerMD"
      @close="closeSettings"
      @button-click="closeSettings"
    >
      <Settings />
    </BottomSheet>
    <DownloadAppModal />
    <ProfileModal />
  </div>
</template>

<script>
import SideModal from "@/components/buy/conversation/SideModal.vue";
import {
  MAGIC_BANNER_TO_HOMEPAGE,
  MAGIC_MY_OLX,
  MAGIC_MY_OLX_SETTINGS,
  MAGIC_NEW_CONVERSATION,
} from "@/constants/eventsConstants";
import { MOBILE_CTA_VISIBLE } from "@/constants/storeLinks";
import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Dropdown } from "floating-vue";
import resolveConfig from "tailwindcss/resolveConfig";
import { mapGetters, mapState } from "vuex";
import tailwindConfig from "../../../tailwind.config";
import BottomSheet from "./BottomSheet.vue";
import DownloadAppModal from "./DownloadAppModal.vue";
import DownloadButtonTrigger from "./DownloadButtonTrigger.vue";
import DropdownPopper from "./DropdownPopper.vue";
import LoginButton from "./LoginButton.vue";
import LogoutButton from "./LogoutButton.vue";
import Settings from "./Settings.vue";
import SwitchToChatButton from "./SwitchToChatButton.vue";
import MagicSearchIcon from "../ui/icons/MagicSearchIcon.vue";
import { isMobileExperiment } from "@/utils/experiments";
import ProfileButton from "../search/actions/ProfileButton.vue";
import ProfileModal from "../search/actions/ProfileModal.vue";
import { isInIframe } from "@/utils/embedded";

export default {
  components: {
    DownloadButtonTrigger,
    DownloadAppModal,
    Disclosure,
    HeadlessMenu: Menu,
    HeadlessMenuButton: MenuButton,
    HeadlessMenuItem: MenuItem,
    HeadlessMenuItems: MenuItems,
    LoginButton,
    LogoutButton,
    SideModal,
    Settings,
    BottomSheet,
    Dropdown,
    DropdownPopper,
    SwitchToChatButton,
    MagicSearchIcon,
    ProfileButton,
    ProfileModal,
  },
  data() {
    return {
      settingsVisible: false,
      menuOpen: false,
      MAGIC_BANNER_TO_HOMEPAGE,
      MAGIC_MY_OLX,
      MAGIC_MY_OLX_SETTINGS,
      MAGIC_NEW_CONVERSATION,
      MOBILE_CTA_VISIBLE,
      config: resolveConfig(tailwindConfig),
      showProfiler:
        process.env.VUE_APP_STAGE !== "production" &&
        process.env.VUE_APP_STAGE !== "staging",
    };
  },
  computed: {
    ...mapState("messages", ["messages"]),
    ...mapGetters("floatingItems", ["onboardingTipVisible"]),
    filteredLinks: function () {
      return this.navigationLinks.filter(
        (link) =>
          (link.public && !this.isAuthenticated) ||
          (!link.public && this.isAuthenticated),
      );
    },
    isAuthenticated: function () {
      return this.$auth0.isAuthenticated.value;
    },
    shouldShowBackButton: function () {
      return ["Buy"].indexOf(this.$route.name) > -1;
    },
    navigationLinks: function () {
      return this.$store.state.navigation.links;
    },
    screenWidth: function () {
      return this.$store.state.windowSize.windowWidth;
    },
    smallerMD: function () {
      return this.screenWidth <= parseInt(this.config.theme.screens.md);
    },
    modeSwitchEnabled: function () {
      return isMobileExperiment();
    },
    isInIframe: function () {
      return isInIframe();
    },
  },
  methods: {
    closeSettings() {
      this.settingsVisible = false;
    },
    showSettings() {
      this.settingsVisible = true;
    },
    createNewSession() {
      this.$store.dispatch("messages/createNewSession");
    },
    navigateToHome() {
      if (!this.modeSwitchEnabled) return;
      this.$router.push({ name: "Home" });
    },
    handleBackOrRedirect() {
      const previousState = window.history.state;

      if (
        previousState &&
        previousState.back &&
        previousState.back.startsWith("/search")
      ) {
        window.history.back();
      } else {
        this.$router.push("/search");
      }
    },
  },
};
</script>
