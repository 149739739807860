import { camelToSnake } from "./string";

export function omit(obj, keys) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  );
}

export function convertKeysToSnakeCase(obj) {
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const snakeCaseKey = camelToSnake(key);
    acc[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
    return acc;
  }, {});
}

export function safeParseJSON(json) {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}

export function isJSONObject(obj) {
  return obj !== null && typeof obj === "object" && obj.constructor === Object;
}
