<template>
  <div
    class="flex min-w-[280px] flex-col rounded-xl border bg-white p-4 shadow"
  >
    <div class="mb-2 text-sm font-medium text-gray-500">
      {{ type }} {{ $t("magic_search_two_point.modal.pick") }}
    </div>
    <BaseImage
      v-if="image"
      :src="image"
      class="mb-4 aspect-[7/8] w-full rounded-lg object-cover"
      :alt="title"
    />
    <div class="space-y-3">
      <div>
        <h4 class="font-medium">{{ title }}</h4>
        <p class="text-lg font-bold text-teal-600">{{ price }} zł</p>
      </div>
      <ul class="space-y-1">
        <li
          v-for="feature in features"
          :key="feature"
          class="flex gap-2 text-sm text-gray-600"
        >
          <CheckIcon class="size-4 shrink-0 text-teal-600" />
          <span>{{ feature }}</span>
        </li>
      </ul>
      <ShoppingGuideLink
        v-if="link"
        v-track:click="{
          ...$EVENTS.HYBRID.MAGIC_AD_LINK,
          ad_id: id,
          magic_ad_source: 'picks',
        }"
        :href="link"
      >
        <BaseButton variant="tertiary" class="mt-2 w-full">
          {{ $t("magic_search_two_point.modal.view_details")
          }}<ExternalIcon class="size-4" />
        </BaseButton>
      </ShoppingGuideLink>
    </div>
  </div>
</template>

<script>
import ExternalIcon from "@/components/ui/icons/ExternalIcon.vue";
import CheckIcon from "@/components/ui/icons/CheckIcon.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import ShoppingGuideLink from "./ShoppingGuideLink.vue";
import BaseImage from "@/components/ui/Image.vue";

export default {
  name: "ShoppingPickItem",
  components: {
    CheckIcon,
    ExternalIcon,
    BaseButton,
    ShoppingGuideLink,
    BaseImage,
  },
  props: {
    type: undefined,
    id: undefined,
    title: undefined,
    price: undefined,
    link: undefined,
    image: undefined,
    features: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  methods: {},
};
</script>
