<template>
  <BaseButton variant="primary" :floating="true" @click="onShowProfileSelect">
    <img
      :class="$twMerge('size-4')"
      src="@/assets/icons/profile_icon.svg"
      alt="profile-icon"
    />
    <span v-if="selectedProfile" class="self-start">{{ selectedProfile }}</span>
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import {
  getItem,
  profileStorageName,
  profileStorageEventName,
} from "@/utils/storage";
import { mapMutations } from "vuex";

export default {
  name: "ProfileButton",
  components: {
    BaseButton,
  },
  data() {
    return {
      selectedProfile: null,
    };
  },
  computed: {},
  mounted() {
    this.selectedProfile = getItem(profileStorageName);
    window.addEventListener(profileStorageEventName, (event) => {
      this.selectedProfile = event.detail.profile;
    });
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_PROFILE_MODAL_OPEN"]),
    onShowProfileSelect() {
      this.SET_PROFILE_MODAL_OPEN(true);
    },
  },
};
</script>
