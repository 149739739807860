<template>
  <div
    v-if="shoppingGuideData.trends_analysis"
    class="rounded-xl border bg-white bg-gradient-to-br from-gray-50 to-gray-100 p-6 shadow"
  >
    <h3 class="mb-2 text-xl font-semibold">
      {{ $t("magic_search_two_point.modal.market_prices") }}
    </h3>
    <p class="mb-4 text-sm text-gray-600">
      {{ $t("magic_search_two_point.modal.based_on_market_analysis") }}
    </p>
    <div class="mb-6 flex flex-wrap gap-2">
      <div
        class="inline-flex items-center gap-2 rounded-full bg-white px-3 py-1 text-sm"
      >
        <LuicideIcon
          name="shield"
          class="size-4 text-teal-600"
          :stroke-width="2"
        /><span class="text-gray-900"
          >{{ $t("magic_search_two_point.modal.avg") }}.
          {{ shoppingGuideData.trends_analysis.average_price }} zł</span
        >
      </div>
      <div
        class="inline-flex items-center gap-2 rounded-full bg-white px-3 py-1 text-sm text-teal-600"
      >
        <LuicideIcon
          name="search"
          class="size-4 text-teal-600"
          :stroke-width="2"
        />
        <span
          >{{ $t("magic_search_two_point.modal.market_budget") }}:
          {{ shoppingGuideData.trends_analysis.budget_highlight }}</span
        >
      </div>
      <div
        class="inline-flex items-center gap-2 rounded-full bg-white px-3 py-1 text-sm"
      >
        <LuicideIcon
          name="hard-drive"
          class="size-4 text-teal-600"
          :stroke-width="2"
        />
        <span class="text-gray-900"
          >{{ $t("magic_search_two_point.modal.market_premium") }}:
          {{ shoppingGuideData.trends_analysis.premium_highlight }}</span
        >
      </div>
      <div
        class="inline-flex items-center gap-2 rounded-full bg-white px-3 py-1 text-sm text-amber-600"
      >
        <LuicideIcon name="triangle-alert" class="size-4" :stroke-width="2" />
        <span
          >{{ $t("magic_search_two_point.modal.avoid_under") }}
          {{ shoppingGuideData.trends_analysis.avoid_under }} zł</span
        >
      </div>
    </div>
    <div class="relative h-20">
      <div class="absolute inset-x-0 top-0 h-4 rounded-full bg-gray-200">
        <div
          class="h-full rounded-full bg-gradient-to-r from-teal-200 via-teal-400 to-teal-600 opacity-20"
        ></div>
        <div
          class="absolute left-1/3 top-0 h-full w-1/3 rounded-full bg-teal-500 opacity-40"
        ></div>
      </div>
      <div class="absolute top-6 flex w-full justify-between text-sm">
        <div>
          <div class="text-gray-900">
            {{ shoppingGuideData.trends_analysis.budget }} zł
          </div>
          <div class="text-gray-600">
            {{ $t("magic_search_two_point.modal.market_budget") }}
          </div>
        </div>
        <div>
          <div class="font-medium text-teal-600">
            {{ shoppingGuideData.trends_analysis.sweet_spot }} zł
          </div>
          <div class="font-medium text-teal-700">
            {{ $t("magic_search_two_point.modal.market_sweet_spot") }}
          </div>
        </div>
        <div>
          <div class="text-gray-900">
            {{ shoppingGuideData.trends_analysis.premium }} zł
          </div>
          <div class="text-gray-600">
            {{ $t("magic_search_two_point.modal.market_premium") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LuicideIcon from "@/components/ui/LuicideIcon.vue";

export default {
  name: "MarketPrices",
  components: { LuicideIcon },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: {},
};
</script>
