<template>
  <div class="z-0 w-full gap-2 border-b bg-white">
    <div class="container mx-auto p-0">
      <div class="flex px-4 py-3">
        <!-- TextInput -->
        <div
          :class="[
            'relative box-border flex h-8.5 w-full items-center rounded',
            'border border-solid border-grey-400',
          ]"
        >
          <SearchBarImagePreview
            :file-reading="fileReading"
            :preview-image="previewImage"
            @file-cleared="onFileCleared"
          />

          <input
            ref="searchInput"
            v-model="localQueryText"
            type="text"
            :placeholder="placeholder"
            class="h-full grow rounded border-none px-2 text-sm font-normal leading-4.5 text-grey-600 focus:ring-0"
            @change="onChange"
            @keyup.enter="onEnter"
          />
          <!-- Action Buttons -->
          <div class="mr-1 flex items-center gap-1">
            <Transition name="scale-up" duration="300">
              <div
                v-if="!disabled"
                class="flex items-center transition-all duration-300"
              >
                <BaseButton variant="icon" class="" @click="onClear">
                  <CrossIcon class="size-5 stroke-1 font-thin text-gray-500" />
                </BaseButton>
                <div class="mx-1 h-4 w-0.5 bg-teal-500"></div>
              </div>
            </Transition>
            <SearchBarImageUploader
              @file-change="onImageChange"
              @file-will-read="onFileReading"
            />
            <BaseButton
              class="ml-1"
              variant="icon"
              :disabled="disabled"
              @click="onEnter"
            >
              <MagicSearchIcon class="mr-1 size-5 text-grey-700" />
            </BaseButton>
          </div>
        </div>
      </div>

      <SearchBarTags class="mx-4 md:hidden" />
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import CrossIcon from "@/components/ui/icons/CrossIcon.vue";
import MagicSearchIcon from "@/components/ui/icons/MagicSearchIcon.vue";
import { moveToNewSearch } from "@/store/utils/hybrid";
import debounce from "lodash.debounce";
import { mapGetters, mapMutations } from "vuex";
import SearchBarImagePreview from "./SearchBarImagePreview.vue";
import SearchBarImageUploader from "./SearchBarImageUploader.vue";
import SearchBarTags from "./SearchBarTags.vue";

const DEBOUNCE_TIME = 500;

export default {
  name: "SearchBar",
  components: {
    CrossIcon,
    BaseButton,
    SearchBarTags,
    SearchBarImagePreview,
    SearchBarImageUploader,
    MagicSearchIcon,
  },
  data() {
    return {
      localQueryText: "",
      previewImage: null,
      fileReading: false,
    };
  },
  computed: {
    ...mapGetters("search", ["loading", "queryText", "uploadedImage"]),
    ...mapGetters("hybridOnboarding", ["mainHighlight"]),
    imageUploading() {
      return this.previewImage ? !this.uploadedImage : false;
    },
    disabled() {
      return (
        this.imageUploading ||
        this.loading ||
        (!this.localQueryText.trim() && !this.uploadedImage)
      );
    },
    placeholder() {
      const isImage = this.mainHighlight === "submit";

      const maxVersions = isImage ? 5 : 9;
      const addition = maxVersions
        ? `.${Math.floor(Math.random() * maxVersions)}`
        : "";

      const rootLabel = isImage
        ? "search.search_bar.input_image_placeholder"
        : "search.search_bar.input_placeholder";
      return this.$t(`${rootLabel}${addition}`);
    },
  },
  watch: {
    localQueryText() {
      this.debouncedUpdateInputBox();
    },
    previewImage() {
      this.debouncedUpdateInputBox();
    },
  },
  created() {
    this.debouncedUpdateInputBox = debounce(() => {
      this.UPDATE_INPUT_BOX({
        current_query: this.localQueryText,
        current_image: this.previewImage,
      });
    }, DEBOUNCE_TIME);
  },
  mounted() {
    this.debouncedUpdateInputBox();
    this._resetInputBar();
  },
  beforeUnmount() {
    this.debouncedUpdateInputBox.cancel();
  },
  methods: {
    ...mapMutations("search", ["CLEAR_FILE_UPLOAD", "CLEAR_FOR_NEW_SEARCH"]),
    ...mapMutations("hybridOnboarding", ["UPDATE_INPUT_BOX"]),
    onEnter() {
      if (this.disabled) return;
      this.$tracking.trackEvent(
        "click",
        this.$EVENTS?.HYBRID?.MAGIC_SEARCH_TEXT,
      );

      this._search();
    },
    onFileReading(value) {
      this.fileReading = value;
    },
    onFileCleared() {
      this._resetInputBar();
      this.CLEAR_FILE_UPLOAD();
      this.$tracking.trackEvent(
        "click",
        this.$EVENTS?.HYBRID?.MAGIC_ATTACHMENT_CANCEL,
      );
    },
    onClear() {
      this.localQueryText = null;
      this.onFileCleared();
    },
    onImageChange(image) {
      this.previewImage = image;
    },
    _resetInputBar() {
      this.localQueryText = "";
      this.fileReading = false;
      this.previewImage = null;
    },
    _search() {
      this.$refs.searchInput.blur();
      const params = {
        newQuery: this.localQueryText,
        newImage: this.uploadedImage,
      };

      this.CLEAR_FOR_NEW_SEARCH();
      this._resetInputBar();

      moveToNewSearch(params);
    },
  },
};
</script>
