<template>
  <Transition name="fade">
    <div
      v-if="shoppingGuideModalOpen"
      :class="
        $twMerge(
          'fixed bottom-0 z-50 flex md:items-center md:justify-center items-end',
          'bg-black bg-opacity-70 md:inset-0 size-full',
        )
      "
    >
      <Reasonings
        v-if="showReasonings"
        expand-by-default
        class="hidden h-[90%] w-1/3 bg-white p-4 shadow-lg md:block"
      />
      <div
        :class="
          $twMerge(
            'flex w-full flex-col rounded-t-lg bg-white shadow-lg',
            'transition-all duration-300 md:w-11/12 md:rounded md:max-w-xl h-[90%]',
          )
        "
      >
        <div class="flex flex-col border-b-1 border-solid border-b-gray-400">
          <div class="flex h-10 items-center justify-center">
            <Handle />
          </div>
          <div class="px-4">
            <ModalHeader
              :title="$t('magic_search_two_point.modal_header.shopping_guide')"
              @close="onClose"
            />
          </div>
        </div>
        <div class="flex flex-1 overflow-auto pt-2">
          <ShoppingGuideContent
            v-if="!shoppingGuideDataError && !shoppingGuideDataLoading"
          />
          <Loader
            v-if="shoppingGuideDataLoading"
            class="w-full justify-center p-20"
          />
          <ShoppingGuideError />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  allowBackgroundScroll,
  blockBackgroundScroll,
} from "@/utils/scrollUtils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ModalHeader from "./ModalHeader";
import ShoppingGuideContent from "./ShoppingGuideContent";
import ShoppingGuideError from "./ShoppingGuideError.vue";
import Handle from "@/components/ui/icons/Handle.vue";
import Loader from "@/components/ui/Loader.vue";
import TrackingService from "../../../services/TrackingService";
import Reasonings from "../Reasonings.vue";

export default {
  name: "ShoppingGuideModal",
  components: {
    ModalHeader,
    Handle,
    ShoppingGuideContent,
    ShoppingGuideError,
    Loader,
    Reasonings,
  },
  data() {
    return {
      showReasonings:
        process.env.VUE_APP_STAGE !== "production" &&
        process.env.VUE_APP_STAGE !== "staging",
    };
  },
  computed: {
    ...mapGetters("guideActions", [
      "shoppingGuideModalOpen",
      "shoppingGuideDataError",
      "shoppingGuideDataLoading",
    ]),
  },
  watch: {
    shoppingGuideModalOpen(value) {
      if (value) {
        blockBackgroundScroll();
        this.$nextTick(() => {
          if (this.$refs.messageInput) {
            this.$refs.messageInput.focus();
          }
        });
      } else {
        allowBackgroundScroll();
      }
    },
    $route() {
      this.closeDialog();
    },
  },
  methods: {
    ...mapActions("guideActions", ["resetShoppingGuide"]),
    ...mapMutations("guideActions", ["SET_SHOPPING_GUIDE_MODAL_OPEN"]),
    closeDialog() {
      this.resetShoppingGuide();
      this.SET_SHOPPING_GUIDE_MODAL_OPEN(false);
    },
    onClose() {
      this.closeDialog();
      TrackingService.trackEvent(
        "click",
        this.$EVENTS.HYBRID.MAGIC_SHOPPING_GUIDE_CLOSE,
      );
    },
  },
};
</script>
