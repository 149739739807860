<template>
  <div v-if="shoppingGuideData.picks">
    <h3 class="mb-4 text-lg font-semibold">
      {{ $t("magic_search_two_point.modal.our_picks") }}
    </h3>
    <div class="flex gap-4 overflow-x-auto pb-6">
      <template
        v-for="(value, key) in shoppingGuideData.picks"
        :key="value?.product_id"
      >
        <ShoppingPickItem
          v-if="value?.title"
          :id="value.product_id"
          :type="$t('magic_search_two_point.modal.market_' + key)"
          :title="value.title"
          :features="value.features"
          :price="value.price"
          :image="value.product_image"
          :link="value.product_link"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShoppingPickItem from "./ShoppingPickItem.vue";

export default {
  name: "ShoppingPicks",
  components: { ShoppingPickItem },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: {},
};
</script>
