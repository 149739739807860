// Core Events

export const MAGIC_HOMEPAGE = {
  event_name: "magic_homepage",
  event_flow: "homepage",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_START_NOW = {
  event_name: "magic_start_now",
  event_flow: "homepage",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_MY_OLX = {
  event_name: "magic_my_olx",
  event_flow: "homepage/product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_MY_OLX_SETTINGS = {
  event_name: "magic_my_olx_settings",
  event_flow: "homepage/product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_MY_OLX_SETTINGS_APPLY = {
  event_name: "magic_my_olx_settings_apply",
  event_flow: "homepage/product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_MY_OLX_LOGOUT = {
  event_name: "magic_my_olx_logout",
  event_flow: "homepage/product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_HOMEPAGE_LOGIN = {
  event_name: "magic_homepage_login",
  event_flow: "homepage",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_LOADED = {
  event_name: "magic_loaded",
  event_flow: "homepage",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_LOADED_CONTEXTUAL = {
  event_name: "magic_loaded",
  event_flow: "contextual_entry",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_BANNER_TO_HOMEPAGE = {
  event_name: "magic_banner_to_homepage",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_SEARCH_DEFAULT_PROMPT = {
  event_name: "magic_search_default_prompt",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_SEARCH_TEXT = {
  event_name: "magic_search_text",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_STOP_GENERATION = {
  event_name: "magic_stop_generation",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_SEARCH_RESULTS = {
  event_name: "magic_search_results",
  event_flow: "product",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_AD_LINK = {
  event_name: "magic_ad_link",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_SMART_COMPARE = {
  event_name: "magic_smart_compare",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_ADD_FAVOURITE = {
  event_name: "magic_add_favourite",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_REMOVE_FAVOURITE = {
  event_name: "magic_remove_favourite",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_THUMBS_UP = {
  event_name: "magic_thumbs_up",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_THUMBS_DOWN = {
  event_name: "magic_thumbs_down",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_SUGGESTED_PROMPT_CLICK = {
  event_name: "magic_suggested_prompt_click",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_NEW_CONVERSATION = {
  event_name: "magic_new_conversation",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_ATTACHMENT_CLICK = {
  event_name: "magic_attachment_click",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_ATTACHMENT_UPLOAD = {
  event_name: "magic_attachment_upload",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_ATTACHMENT_CANCEL = {
  event_name: "magic_attachment_cancel",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_CAMERA_CLICK = {
  event_name: "magic_camera_click",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_CAMERA_TAKE_PHOTO = {
  event_name: "magic_camera_take_photo",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_CAMERA_CLOSE = {
  event_name: "magic_camera_close",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_MICROPHONE_CLICK = {
  event_name: "magic_microphone_click",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const MAGIC_ITEM_INFO = {
  event_name: "magic_item_info",
  event_flow: "product",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_MOBILE_ENTRY = {
  event_name: "magic_mobile_entry",
  event_flow: "homepage",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_WEB_ENTRY = {
  event_name: "magic_web_entry",
  event_flow: "homepage",
  event_type: "page",
  magic_version: "chat",
};

export const MAGIC_FIND_SIMILAR = {
  event_name: "magic_find_similar",
  event_flow: "product",
  event_type: "click",
  magic_version: "chat",
};

export const EVENTS = {
  CORE: {
    MAGIC_HOMEPAGE: {
      event_name: "magic_homepage",
      event_flow: "homepage",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_START_NOW: {
      event_name: "magic_start_now",
      event_flow: "homepage",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_MY_OLX: {
      event_name: "magic_my_olx",
      event_flow: "homepage/product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_MY_OLX_SETTINGS: {
      event_name: "magic_my_olx_settings",
      event_flow: "homepage/product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_MY_OLX_SETTINGS_APPLY: {
      event_name: "magic_my_olx_settings_apply",
      event_flow: "homepage/product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_MY_OLX_LOGOUT: {
      event_name: "magic_my_olx_logout",
      event_flow: "homepage/product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_HOMEPAGE_LOGIN: {
      event_name: "magic_homepage_login",
      event_flow: "homepage",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_LOADED: {
      event_name: "magic_loaded",
      event_flow: "homepage",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_LOADED_CONTEXTUAL: {
      event_name: "magic_loaded",
      event_flow: "contextual_entry",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_BANNER_TO_HOMEPAGE: {
      event_name: "magic_banner_to_homepage",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_SEARCH_DEFAULT_PROMPT: {
      event_name: "magic_search_default_prompt",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_SEARCH_TEXT: {
      event_name: "magic_search_text",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_STOP_GENERATION: {
      event_name: "magic_stop_generation",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_SEARCH_RESULTS: {
      event_name: "magic_search_results",
      event_flow: "product",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_AD_LINK: {
      event_name: "magic_ad_link",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_SMART_COMPARE: {
      event_name: "magic_smart_compare",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_ADD_FAVOURITE: {
      event_name: "magic_add_favourite",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_REMOVE_FAVOURITE: {
      event_name: "magic_remove_favourite",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_THUMBS_UP: {
      event_name: "magic_thumbs_up",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_THUMBS_DOWN: {
      event_name: "magic_thumbs_down",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_SUGGESTED_PROMPT_CLICK: {
      event_name: "magic_suggested_prompt_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_NEW_CONVERSATION: {
      event_name: "magic_new_conversation",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_ATTACHMENT_CLICK: {
      event_name: "magic_attachment_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_ATTACHMENT_UPLOAD: {
      event_name: "magic_attachment_upload",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_ATTACHMENT_CANCEL: {
      event_name: "magic_attachment_cancel",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_CAMERA_CLICK: {
      event_name: "magic_camera_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_CAMERA_TAKE_PHOTO: {
      event_name: "magic_camera_take_photo",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_CAMERA_CLOSE: {
      event_name: "magic_camera_close",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_MICROPHONE_CLICK: {
      event_name: "magic_microphone_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_ITEM_INFO: {
      event_name: "magic_item_info",
      event_flow: "product",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_MOBILE_ENTRY: {
      event_name: "magic_mobile_entry",
      event_flow: "homepage",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_WEB_ENTRY: {
      event_name: "magic_web_entry",
      event_flow: "homepage",
      event_type: "page",
      magic_version: "chat",
    },
    MAGIC_FIND_SIMILAR: {
      event_name: "magic_find_similar",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_DOWNLOAD_APP_CLICK: {
      event_name: "magic_download_app_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_DOWNLOAD_APP_VISIBLE: {
      event_name: "magic_download_app_visible",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
    MAGIC_DOWNLOAD_APP_CANCEL: {
      event_name: "magic_download_app_cancel",
      event_flow: "product",
      event_type: "click",
      magic_version: "chat",
    },
  },
  HYBRID: {
    MAGIC_LOADED: {
      event_name: "magic_loaded",
      event_flow: "homepage",
      event_type: "page",
      magic_version: "search",
    },
    MAGIC_LOADED_CONTEXTUAL: {
      event_name: "magic_loaded",
      event_flow: "contextual_entry",
      event_type: "page",
      magic_version: "search",
    },
    MAGIC_SEARCH_RESULTS: {
      event_name: "magic_search_results",
      event_flow: "product",
      event_type: "page",
      magic_version: "search",
    },
    MAGIC_AD_LINK: {
      event_name: "magic_ad_link",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_SMART_COMPARE: {
      event_name: "magic_smart_compare",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_ADD_FAVOURITE: {
      event_name: "magic_add_favourite",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_REMOVE_FAVOURITE: {
      event_name: "magic_remove_favourite",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_THUMBS_UP: {
      event_name: "magic_thumbs_up",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_THUMBS_DOWN: {
      event_name: "magic_thumbs_down",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_ATTACHMENT_CLICK: {
      event_name: "magic_attachment_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_ATTACHMENT_UPLOAD: {
      event_name: "magic_attachment_upload",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_ATTACHMENT_CANCEL: {
      event_name: "magic_attachment_cancel",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_ITEM_INFO: {
      event_name: "magic_item_info",
      event_flow: "product",
      event_type: "page",
      magic_version: "search",
    },
    MAGIC_FIND_SIMILAR: {
      event_name: "magic_find_similar",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_SEARCH_TEXT: {
      event_name: "magic_search_text",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_DOWNLOAD_APP_CLICK: {
      event_name: "magic_download_app_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_DOWNLOAD_APP_VISIBLE: {
      event_name: "magic_download_app_visible",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_DOWNLOAD_APP_CANCEL: {
      event_name: "magic_download_app_cancel",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    // New Events Added
    MAGIC_GO_TO_CHAT: {
      event_name: "magic_go_to_chat",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_FILTER_SELECTED: {
      event_name: "magic_filter_selected",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_FILTER_INPUT: {
      event_name: "magic_filter_input",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_HELPER_ANSWER_SELECTED: {
      event_name: "magic_helper_answer_selected",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_HELPER_INPUT: {
      event_name: "magic_helper_input",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_NEXT_SEARCH_SELECTED: {
      event_name: "magic_next_search_selected",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_GROUP_SHOW_MORE: {
      event_name: "magic_group_show_more",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_SHOPPING_GUIDE_VIEWED: {
      event_name: "magic_shopping_guide_viewed",
      event_flow: "product",
      event_type: "page",
      magic_version: "search",
    },
    MAGIC_SHOPPING_GUIDE_CLICK: {
      event_name: "magic_shopping_guide_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_SHOPPING_GUIDE_CLOSE: {
      event_name: "magic_shopping_guide_close",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
    MAGIC_SHOPPING_GUIDE_LOADED: {
      event_name: "magic_shopping_guide_loaded",
      event_flow: "product",
      event_type: "page",
      magic_version: "search",
    },
    MAGIC_SHOPPING_GUIDE_SEARCH_CLICK: {
      event_name: "magic_shopping_guide_search_click",
      event_flow: "product",
      event_type: "click",
      magic_version: "search",
    },
  },
};
