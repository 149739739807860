<template>
  <div class="static-full-page-content w-screen">
    <iframe class="size-full" src="http://localhost:8080/search" />
  </div>
</template>

<script>
export default {
  name: "EmbeddedContainer",
};
</script>
