import { getClientSessionParams } from "@/auth/magic_auth";
import { EVENTS } from "@/constants/eventsConstants";
import { i18n } from "@/locale/i18n";
import {
  getInstant,
  getProductTags,
  postGroupsByQuery,
  postMagicHelper,
  postMagicSuggestions,
  postSearchHybridStream,
} from "@/services/SearchServiceV2";
import { abortAllControllersByNamespace } from "@/services/abortControllersService";
import logger from "@/services/logger";
import {
  createCustomList,
  MESSAGE_SEPARATOR,
  moveToNewSearch,
} from "@/store/utils/hybrid";
import { isAbortedError } from "@/utils/error";
import { chunkHandler } from "@/utils/streaming";
import { isEqual, isUndefined } from "lodash";
import TrackingService from "../../services/TrackingService";

const defaultState = () => ({
  queryText: "",
  queryTags: [],
  queryImage: null,
  queryProductId: null,
  uploadedImage: null,
  originProduct: null,
  loading: false,
  instantOnFlight: null,
  instantResponse: null,
  queryGroups: null,
  queryGroupsLoading: false,
  onflight: null,
  sortMethod: "relevance",
  hybridSearch: null,
  nextSearchSuggestions: null,
  productTags: null,
  productTagsLoading: false,
  currentSearchParams: null,
  reasonings: [],
});

const state = defaultState();

const getters = {
  queryText: (state) => state.queryText,
  queryTags: (state) => state.queryTags,
  queryImage: (state) => state.queryImage,
  loading: (state, getters, rootState, rootGetters) =>
    state.hybridSearch
      ? getters["products"]?.length === 0 &&
        !rootGetters["hybridHelper/mainSummary"]
      : state.loading,
  searchId: (state) => state.hybridSearch?.id,
  products: (state) => {
    const products = state.hybridSearch?.ranked_products ?? [];
    switch (state.sortMethod) {
      case "price_low":
        return [...products].sort((a, b) => a.price - b.price);
      case "price_high":
        return [...products].sort((a, b) => b.price - a.price);
      default:
        return products;
    }
  },
  productsLoading: (state) =>
    state.hybridSearch?.status
      ? ["created", "building", "visual_search", "find_similar"].includes(
          state.hybridSearch.status,
        )
      : false,
  isBroadRequest: (state) => state.hybridSearch?.request_type === "broad",
  sortMethod: (state) => state.sortMethod,
  productsLength: (state) => state.hybridSearch?.products_length ?? 0,
  uploadedImage: (state) => state.uploadedImage,
  visualSearchInputs: (state) => state.hybridSearch?.visual_search_inputs,
  originProduct: (state) => state.hybridSearch?.origin_product,
  groups: (state) => state.hybridSearch?.groups ?? [],
  queries: (state) => state.hybridSearch?.queries ?? [],
  status: (state) => state.hybridSearch?.status,
  list: (state, getters) =>
    createCustomList(
      getters.products,
      getters.status === "grouping" || state.queryGroupsLoading,
      getters.groups,
      state.queryGroups,
    ),
  instantProducts: (state) => state.instantResponse,
  refineButtonEnabled: (_, getters) =>
    getters.products.length > 0 || (getters.instantProducts?.length ?? 0) > 0,
  nextSearchSuggestions: (state) => state.nextSearchSuggestions,
  productTags: (state) =>
    state.productTags?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.short_info,
      }),
      {},
    ),
  productTagsLoading: (state) => state.productTagsLoading,
  currentSearchParams: (state) => state.currentSearchParams,
  reasonings: (state) => state.reasonings,
};

const actions = {
  async search({ dispatch, commit, getters }, params) {
    const curr = getters["currentSearchParams"];
    if (isEqual(params, curr)) return;

    abortAllControllersByNamespace("hybrid");
    const isEmpty = Object.values(params)
      .map((v) => (Array.isArray(v) ? v.length : v))
      .every((v) => !v);

    if (isEmpty) {
      dispatch("reset");
      return;
    }

    dispatch("reset");
    dispatch("searchStreaming", params);
    dispatch("fetchInstantResults", params);
    commit("SET_LAST_SEARCH_PARAMS", params);
  },
  async searchStreaming({ commit, state, dispatch, rootGetters }, params) {
    try {
      let buffer = "";
      commit("SET_LOADING", true);

      const userInfo = getClientSessionParams();
      const language = rootGetters["settings/selectedLocale"] ?? "PL";

      await postSearchHybridStream(
        { ...params, ...userInfo, language },
        (chunk) => {
          buffer = chunkHandler(
            buffer + chunk,
            (data) => {
              logger.log("searchStreaming", data?.status?.toUpperCase(), data);
              dispatch("searchDataSideEffects", { data });

              if (data.status === "created") {
                commit("SET_HYBRID_SEARCH", data);
              } else if (data.status === "failed") {
                dispatch("handleError", data);
              } else {
                commit("SET_HYBRID_SEARCH", { ...state.hybridSearch, ...data });
              }
            },
            MESSAGE_SEPARATOR,
          );
        },
      );
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("searchStreaming error:", e);
      dispatch("handleError", e);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchQueryBasedGroups(
    { state, rootGetters, commit, getters, dispatch },
    params,
  ) {
    try {
      commit("SET_QUERY_GROUPS_LOADING", true);
      const language = rootGetters["settings/selectedLocale"] ?? "PL";
      const { groups, reasoning } = await postGroupsByQuery({
        ...params,
        language,
      });
      const rcommit = (mutation, payload) => {
        commit(mutation, payload, { root: true });
      };
      logger.log("queryGroups", groups);
      if (groups) {
        rcommit("hybridOnboarding/SET_QUERY_BASED_GROUPS_SUCCESS", {
          q_group: groups,
          hybrid_search_id: state.hybridSearch?.id,
        });
        commit("SET_QUERY_GROUPS", groups);
        const isBroadRequest = getters["isBroadRequest"];

        if (isBroadRequest) {
          dispatch("fetchMagicHelper", {
            status: "completed",
            query_based_group_titles: groups.map((g) => g.title),
          });
        }

        if (reasoning) {
          commit("ADD_REASONING", {
            source: "Query Groups",
            text: reasoning,
          });
        }
      }
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("fetchQueryBasedGroups error:", e);
    } finally {
      logger.log("fetchQueryBasedGroups done");
      commit("SET_QUERY_GROUPS_LOADING", false);
    }
  },
  async searchDataSideEffects(
    { dispatch, state, rootGetters, getters, commit },
    { data },
  ) {
    if (data.status?.toUpperCase() === "GROUPING") {
      const productIds = data?.ranked_products.map((product) => product.id);

      TrackingService.trackPage({
        ...EVENTS.HYBRID.MAGIC_SEARCH_RESULTS,
        ad_impressions: productIds,
      });
    }
    logger.log("searchDataSideEffects", data.status?.toUpperCase(), {
      data,
      hybrid: state.hybridSearch,
    });

    const language = rootGetters["settings/selectedLocale"] ?? "PL";
    const rcommit = (mutation, payload) => {
      commit(mutation, payload, { root: true });
    };

    if (data.status === "created") {
      if (!state.queryProductId) {
        dispatch("fetchQueryBasedGroups", {
          queryText: state.queryText,
          queryTags: state.queryTags,
          language,
          hybrid_search_id: data.id,
        });
      }
      rcommit("hybridOnboarding/SET_NEW_SEARCH_CREATED", {
        hybrid_search_id: data.id,
      });
    }

    if (data?.groups) {
      rcommit("hybridOnboarding/SET_NEW_SEARCH_P_GROUP", {
        hybrid_search_id: state.hybridSearch?.id,
        p_group: data.groups,
      });
    }

    const isBroadRequest = isUndefined(data.request_type)
      ? getters["isBroadRequest"]
      : data.request_type === "broad";
    const HELPER_STATUSES = ["building", "visual_search", "completed"];
    if (HELPER_STATUSES.includes(data.status)) {
      // Leave summary fetch to query based groups
      if (data.status === "completed" && isBroadRequest) return;
      dispatch("fetchMagicHelper", data);
    }

    if (data.status === "grouping") {
      dispatch("fetchSuggestions");
      dispatch("guideActions/fetchShoppingGuideEntry", null, { root: true });
    }

    if (data.ranked_products) {
      dispatch("fetchProductTags", data);
      rcommit("hybridOnboarding/SET_NEW_SEARCH_PRODUCTS", {
        hybrid_search_id: state.hybridSearch?.id,
        count: data.ranked_products?.length ?? 0,
      });
    }
  },
  async fetchMagicHelper({ commit, state, rootGetters }, data) {
    try {
      const language = rootGetters["settings/selectedLocale"] ?? "PL";
      const shouldCancelPrev = !!data.query_based_group_titles;
      const response = await postMagicHelper(
        {
          hybrid_search_id:
            state.hybridSearch?.id ?? data.id ?? data.hybrid_search_id,
          status: data.status,
          language,
          query_based_group_titles: data.query_based_group_titles,
        },
        shouldCancelPrev,
      );

      const rcommit = (mutation, payload) => {
        commit(mutation, payload, { root: true });
      };

      if (response.message) {
        rcommit("hybridHelper/SET_SEARCH_MAGIC_SUMMARY", response.message);
      }
      if (response.clarifying_question) {
        rcommit(
          "hybridHelper/SET_CLARIFYING_QUESTION",
          response.clarifying_question,
        );
      }
      if (response.clarifying_question_suggestions) {
        rcommit(
          "hybridHelper/SET_CLARIFYING_QUESTION_SUGGESTIONS",
          response.clarifying_question_suggestions,
        );
      }
      if (response.personalization_reasoning) {
        commit("ADD_REASONING", {
          source: `Magic Helper${data.status ? ` (${data.status})` : ""}`,
          text: response.personalization_reasoning,
        });
      }
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("fetchMagicHelper error:", e);
    }
  },
  async fetchInstantResults({ commit }, params) {
    const { queryText, encoded_filters } = params;

    const hasData = !!queryText || !!encoded_filters;
    const cannotExecute =
      (params.queryTags?.length ?? 0) > 0 || !!params.queryImage;
    if (!hasData || cannotExecute) {
      commit("SET_INSTANT_RESPONSE", null);
      return;
    }

    try {
      const response = await getInstant(params);
      commit("SET_INSTANT_RESPONSE", response);
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Instant error:", e);
      commit("SET_INSTANT_RESPONSE", null);
    }
  },
  async removeQueryTag({ state, dispatch, commit }, tag) {
    const queryTags = state.queryTags.filter((t) => t !== tag);
    commit("SET_QUERY_TAGS", queryTags);
    moveToNewSearch({ newQuery: state.queryText, newTags: queryTags });
    dispatch("search", { queryText: state.queryText, queryTags });
  },
  async fetchSuggestions({ commit, state, rootGetters }) {
    try {
      const id = state.hybridSearch?.id;
      if (!id) {
        logger.error("No hybrid search id to fetch suggestions");
        return;
      }

      const language = rootGetters["settings/selectedLocale"] ?? "PL";
      const response = await postMagicSuggestions({
        hybrid_search_id: id,
        language,
      });
      const { next_query_suggestions, refinement_suggestions } = response;
      commit("SET_SEARCH_SUGGESTIONS", next_query_suggestions);
      commit("hybridActions/SET_REFINE_SUGGESTIONS", refinement_suggestions, {
        root: true,
      });

      if (response.personalization_reasoning) {
        commit("ADD_REASONING", {
          source: "Suggestions",
          text: response.personalization_reasoning,
        });
      }
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Fetch suggestions error:", e);
    }
  },
  async fetchProductTags({ commit, state, rootGetters }, data) {
    commit("SET_PRODUCT_TAGS_LOADING", true);
    const hybrid_search_id = data.id ?? state.hybridSearch?.id;
    const language = rootGetters["settings/selectedLocale"] ?? "PL";
    try {
      const tags = await getProductTags({ hybrid_search_id, language });
      commit("SET_PRODUCT_TAGS", tags);
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Fetch product tags error:", e);
    } finally {
      commit("SET_PRODUCT_TAGS_LOADING", false);
    }
  },
  reset({ commit }) {
    abortAllControllersByNamespace("hybrid");

    commit("CLEAR_FOR_NEW_SEARCH");
    commit("hybridActions/CLEAR_FOR_NEW_SEARCH", null, { root: true });
    commit("hybridHelper/CLEAR_FOR_NEW_SEARCH", null, { root: true });
    commit("guideActions/SET_SHOPPING_GUIDE_DATA", null, { root: true });
    commit("guideActions/SET_SHOPPING_GUIDE_ENTRY", null, { root: true });
  },
  async handleError({ dispatch, commit, getters }, e) {
    logger.error("Search error:", e);

    if (getters["products"].length > 0) return;

    dispatch("reset");
    commit(
      "hybridHelper/SET_MAIN_ERROR",
      i18n.global.t("search.helper.generic_error"),
      { root: true },
    );
  },
  setSortMethod({ commit }, sortMethod) {
    commit("SET_SORT_METHOD", sortMethod);
    commit("hybridOnboarding/USER_CLICKED_SORT", {}, { root: true });
  },
};

const mutations = {
  SET_QUERY_TEXT(state, queryText) {
    state.queryText = queryText;
  },
  SET_QUERY_IMAGE(state, queryImage) {
    state.queryImage = queryImage;
  },
  SET_QUERY_TAGS(state, queryTags) {
    state.queryTags = queryTags;
  },
  SET_QUERY_PRODUCT_ID(state, queryProductId) {
    state.queryProductId = queryProductId;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ONFLIGHT(state, onflight) {
    state.onflight = onflight;
  },
  SET_SORT_METHOD(state, sortMethod) {
    state.sortMethod = sortMethod;
  },
  SET_INSTANT_RESPONSE(state, response) {
    state.instantResponse = response;
  },
  SET_INSTANT_ONFLIGHT(state, onflight) {
    state.instantOnFlight = onflight;
  },
  SET_HYBRID_SEARCH(state, hybridSearch) {
    state.hybridSearch = hybridSearch;
  },
  SET_QUERY_GROUPS(state, queryGroups) {
    state.queryGroups = queryGroups;
  },
  SET_QUERY_GROUPS_LOADING(state, loading) {
    state.queryGroupsLoading = loading;
  },
  PATCH_HYBRID_SEARCH(state, patch) {
    state.hybridSearch = { ...state.hybridSearch, ...patch };
  },
  SET_SEARCH_SUGGESTIONS(state, suggestions) {
    state.nextSearchSuggestions = suggestions;
  },
  SET_UPLOADED_IMAGE(state, image) {
    state.uploadedImage = image;
  },
  CLEAR_FILE_UPLOAD(state) {
    state.uploadedImage = null;
  },
  CLEAR_FOR_NEW_SEARCH(state) {
    const nextProps = defaultState();
    const skipFields = [
      "queryText",
      "queryTags",
      "queryImage",
      "queryProductId",
    ];
    Object.keys(nextProps).forEach((key) => {
      if (skipFields.includes(key)) return;
      state[key] = nextProps[key];
    });
  },
  SET_PRODUCT_TAGS(state, tags) {
    state.productTags = tags;
  },
  SET_PRODUCT_TAGS_LOADING(state, loading) {
    state.productTagsLoading = loading;
  },
  SET_LAST_SEARCH_PARAMS(state, params) {
    state.currentSearchParams = params;
  },
  ADD_REASONING(state, reasoning) {
    state.reasonings = [
      ...state.reasonings.filter((r) => r.source !== reasoning.source),
      reasoning,
    ];
  },
  CLEAR_REASONINGS(state) {
    state.reasonings = [];
  },
  CLEAR_REASONINGS_BY_PATTERN(state, pattern) {
    state.reasonings = state.reasonings.filter(
      (r) => !r.source.startsWith(pattern),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
