<template>
  <Transition name="fade">
    <div
      v-if="profileModalOpen"
      :class="
        $twMerge(
          'fixed bottom-0 z-50 flex md:items-center md:justify-center items-end',
          'bg-black bg-opacity-70 md:inset-0 size-full',
        )
      "
    >
      <div
        :class="
          $twMerge(
            'flex w-full flex-col rounded-t-lg bg-grey-300 p-4 shadow-lg',
            'transition-all duration-300 md:w-11/12 h-4/5 md:rounded md:max-w-xl',
          )
        "
      >
        <div class="flex h-10 items-center justify-center">
          <Handle />
        </div>
        <ModalHeader
          :title="$t('magic_search_two_point.modal_header.profile_select')"
          @close="onClose"
        />
        <ProfileContent />
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  allowBackgroundScroll,
  blockBackgroundScroll,
} from "@/utils/scrollUtils";
import { mapGetters, mapMutations } from "vuex";
import ModalHeader from "./ModalHeader";
import ProfileContent from "./ProfileContent";
import Handle from "@/components/ui/icons/Handle.vue";

export default {
  name: "ProfileModal",
  components: { ModalHeader, ProfileContent, Handle },
  computed: {
    ...mapGetters("hybridActions", ["profileModalOpen"]),
  },
  watch: {
    profileModalOpen(value) {
      if (value) {
        blockBackgroundScroll();
        this.$nextTick(() => {
          if (this.$refs.messageInput) {
            this.$refs.messageInput.focus();
          }
        });
      } else {
        allowBackgroundScroll();
      }
    },
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_PROFILE_MODAL_OPEN"]),
    onClose() {
      this.SET_PROFILE_MODAL_OPEN(false);
    },
  },
};
</script>
