import { parse } from "best-effort-json-parser";
import {
  postShoppingGuideBaseData,
  postShoppingGuideData,
} from "./SearchServiceV2";

const clearResponse = (response) =>
  response
    .replace(/^```json(\n)?/, "")
    .replace(/```$/, "")
    .trim();

export async function postMergedShoppingGuideData(
  { hybrid_search_id, ...rest },
  onData,
) {
  const response = {};
  const parsedResponse = {};
  const onMessageFactory = (type) => (message) => {
    response[type] = response[type] ? response[type] + message : message;
    parsedResponse[type] = parse(clearResponse(response[type]));
    const mergedResponse = {
      ...parsedResponse.base,
      ...parsedResponse.regular,
    };
    onData(mergedResponse);
  };

  await Promise.all([
    postShoppingGuideBaseData(
      { hybrid_search_id, ...rest },
      onMessageFactory("base"),
    ),
    postShoppingGuideData(
      { hybrid_search_id, ...rest },
      onMessageFactory("regular"),
    ),
  ]);
}
