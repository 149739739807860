const storage = localStorage;
const PREFIX = "X-Magic-";
export const profileStorageName = "profile";
export const profileStorageEventName = "profile-changed";

export function getItem(key) {
  try {
    const str = storage.getItem(`${PREFIX}${key}`);
    const data = JSON.parse(str);
    const { value } = data;
    return value;
  } catch {
    removeItem(key);
    return null;
  }
}

export function setItem(key, value) {
  const now = new Date();
  const data = { value, date: now.getTime() };
  storage.setItem(`${PREFIX}${key}`, JSON.stringify(data));
}

function removeItem(key) {
  storage.removeItem(`${PREFIX}${key}`);
}
