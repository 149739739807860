<template>
  <div
    class="mx-4 mt-2 overflow-auto"
    :class="{ 'min-h-40': reasonings.length > 0 && !hidden }"
  >
    <h2 class="flex w-full">
      <span class="text-base font-medium uppercase leading-4 text-magic-800">
        Personalisation Reasonings
      </span>
      <button
        class="ml-auto cursor-pointer text-xs font-medium uppercase text-magic-800"
        @click="hidden = !hidden"
      >
        {{ hidden ? "Show" : "Hide" }}
      </button>
    </h2>
    <ul v-if="!hidden">
      <li
        v-for="reasoning in reasonings"
        :key="reasoning.source"
        class="mb-1 mt-3"
      >
        <h3 class="font-medium">{{ reasoning.source }}</h3>
        <p>
          <span>{{
            !isExpanded(reasoning.source)
              ? `${reasoning.text.slice(0, 120)}...`
              : reasoning.text
          }}</span>
          <span v-if="!expandByDefault" class="ml-2">
            <button
              class="cursor-pointer text-xs font-medium text-magic-800"
              variant="secondary"
              @click="toggleSource(reasoning.source)"
            >
              {{ isExpanded(reasoning.source) ? "Show less" : "Show more" }}
            </button>
          </span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Reasonings",
  props: {
    expandByDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedSources: [],
      hidden: false,
    };
  },
  computed: {
    ...mapGetters("search", ["reasonings"]),
  },
  watch: {
    reasonings(newReasonings) {
      if (!newReasonings.length) {
        this.expandedSources = [];
      }
    },
  },
  methods: {
    toggleSource(source) {
      this.expandedSources = this.expandedSources.includes(source)
        ? this.expandedSources.filter((s) => s !== source)
        : [...this.expandedSources, source];
    },
    isExpanded(source) {
      return this.expandByDefault || this.expandedSources.includes(source);
    },
  },
};
</script>
