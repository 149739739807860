import { i18n } from "@/locale/i18n";
import { cancelAbortController } from "@/services/abortControllersService";
import logger from "@/services/logger";
import { postShoppingGuideEntry } from "@/services/SearchServiceV2";
import { moveToNewSearch } from "@/store/utils/hybrid";
import { isAbortedError } from "@/utils/error";
import { isJSONObject } from "@/utils/object";
import { postMergedShoppingGuideData } from "../../services/ShoppingGuide";

const defaultState = () => ({
  shoppingGuideModalOpen: false,
  shoppingGuideData: null,
  shoppingGuideEntry: null,
  shoppingGuideDataError: null,
  shoppingGuideDataLoading: false,
});

const state = defaultState();

const getters = {
  shoppingGuideModalOpen: (state) => state.shoppingGuideModalOpen,
  shoppingGuideData: (state) => state.shoppingGuideData,
  shoppingGuideEntry: (state) => state.shoppingGuideEntry,
  shoppingGuideDataError: (state) => state.shoppingGuideDataError,
  shoppingGuideDataLoading: (state) => state.shoppingGuideDataLoading,
};

const actions = {
  async fetchShoppingGuideData(
    { commit, rootGetters, dispatch },
    hybrid_search_id,
  ) {
    if (!hybrid_search_id) {
      commit("SET_SHOPPING_GUIDE_DATA", null);
      return;
    }

    try {
      commit("search/CLEAR_REASONINGS_BY_PATTERN", "Shopping Guide", {
        root: true,
      });
      commit("SET_SHOPPING_GUIDE_LOADING", true);
      commit("SET_SHOPPING_GUIDE_ERROR", null);

      const language = rootGetters["settings/selectedLocale"] ?? "PL";
      let isLoading = true;
      let responseData = {};
      await postMergedShoppingGuideData(
        {
          hybrid_search_id,
          language,
        },
        (data) => {
          if (!isJSONObject(data)) {
            commit(
              "SET_SHOPPING_GUIDE_ERROR",
              i18n.global.t("search.helper.generic_error"),
            );
          } else {
            responseData = data;
            commit("SET_SHOPPING_GUIDE_DATA", data);
          }

          if (isLoading) {
            commit("SET_SHOPPING_GUIDE_LOADING", false);
            isLoading = false;
          }
        },
      );
      dispatch("shoppongGuideReasoningSideEffect", responseData);
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Shopping guide error:", e);
      commit("SET_SHOPPING_GUIDE_DATA", null);
      commit("SET_SHOPPING_GUIDE_LOADING", false);
      commit(
        "SET_SHOPPING_GUIDE_ERROR",
        i18n.global.t("search.helper.generic_error"),
      );
    }
  },
  async fetchShoppingGuideEntry({ commit, rootGetters }) {
    const hybrid_search_id = rootGetters["search/searchId"];

    if (!hybrid_search_id) {
      commit("SET_SHOPPING_GUIDE_ENTRY", null);
      return;
    }
    try {
      const language = rootGetters["settings/selectedLocale"] ?? "PL";
      const response = await postShoppingGuideEntry({
        hybrid_search_id,
        language,
      });

      if (response.error) {
        logger.error("Shopping entry error:", response.error);
        commit("SET_SHOPPING_GUIDE_ENTRY", null);
      } else {
        commit("SET_SHOPPING_GUIDE_ENTRY", response);
      }
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Shopping entry error:", e);
      commit("SET_SHOPPING_GUIDE_ENTRY", null);
    }
  },
  resetShoppingGuide({ commit }) {
    cancelAbortController("shopping_guide_stream", "hybrid");
    commit("SET_SHOPPING_GUIDE_DATA");
  },
  makeSearch({ commit, state }, query) {
    commit("SET_SHOPPING_GUIDE_MODAL_OPEN", false);
    commit("SET_SHOPPING_GUIDE_DATA", null);
    commit("SET_SHOPPING_GUIDE_ENTRY", null);
    moveToNewSearch({
      newQuery: query,
      newTags: null,
      newImage: null,
      origin_hybrid_search_id: state.hybridSearch?.id,
    });
  },
  shoppongGuideReasoningSideEffect({ commit }, data) {
    const reasoning = (payload) => {
      commit("search/ADD_REASONING", payload, { root: true });
    };

    if (data.personalization_reasoning) {
      reasoning({
        source: "Shopping Guide (Main)",
        text: data.personalization_reasoning,
      });
    }
    if (data.categories.personalization_reasoning) {
      reasoning({
        source: "Shopping Guide (Categories)",
        text: data.categories.personalization_reasoning,
      });
    }
    if (data.featured_product?.personalization) {
      reasoning({
        source: "Shopping Guide (Featured Product)",
        text: data.featured_product.personalization,
      });
    }
    if (data.picks?.reasoning) {
      reasoning({
        source: "Shopping Guide (Picks)",
        text: data.picks.reasoning,
      });
    }
    if (data.personas.personalization_reasoning) {
      reasoning({
        source: "Shopping Guide (Personas)",
        text: data.personas.personalization_reasoning,
      });
    }
  },
};

const mutations = {
  SET_SHOPPING_GUIDE_MODAL_OPEN: (state, value) =>
    (state.shoppingGuideModalOpen = value),

  SET_SHOPPING_GUIDE_DATA(state, response) {
    state.shoppingGuideData = response;
  },

  SET_SHOPPING_GUIDE_ERROR: (state, error) => {
    state.shoppingGuideDataError = error;
  },

  SET_SHOPPING_GUIDE_ENTRY(state, response) {
    state.shoppingGuideEntry = response;
  },

  SET_SHOPPING_GUIDE_LOADING: (state, loading) => {
    state.shoppingGuideDataLoading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
