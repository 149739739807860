import { stream } from "@/utils/streaming";
import axios from "axios";

const BASE_PATH = `${process.env.VUE_APP_API_URL ?? ""}/api/v1/ads`;

export async function getAd(id) {
  const url = `${BASE_PATH}/${id}`;

  const response = await axios.get(url);
  return response.data;
}

export async function postAdChatCompletionFeedback(id, feedback) {
  const url = `${BASE_PATH}/${id}/chat_completion_feedback`;

  const response = await axios.post(url, feedback);
  return response.data;
}

export async function getAdChatCompletionStream(id, onMessage, queryParams) {
  const p = new URLSearchParams(queryParams);
  if (!p.has("language")) p.append("language", "polish");

  const url = `${BASE_PATH}/${id}/chat_completion?${p.toString()}`;

  return stream(url, { method: "GET" }, onMessage);
}
